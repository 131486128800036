import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './ContactForm.css';

const countryCodes = [
  { name: 'Afghanistan', code: '+93' },
  { name: 'Albania', code: '+355' },
  { name: 'Algeria', code: '+213' },
  { name: 'Andorra', code: '+376' },
  { name: 'Angola', code: '+244' },
  { name: 'Argentina', code: '+54' },
  { name: 'Australia', code: '+61' },
  { name: 'Austria', code: '+43' },
  { name: 'Bangladesh', code: '+880' },
  { name: 'Belgium', code: '+32' },
  { name: 'Brazil', code: '+55' },
  { name: 'Canada', code: '+1' },
  { name: 'China', code: '+86' },
  { name: 'Colombia', code: '+57' },
  { name: 'Denmark', code: '+45' },
  { name: 'Egypt', code: '+20' },
  { name: 'France', code: '+33' },
  { name: 'Germany', code: '+49' },
  { name: 'Greece', code: '+30' },
  { name: 'Hong Kong', code: '+852' },
  { name: 'India', code: '+91' },
  { name: 'Indonesia', code: '+62' },
  { name: 'Ireland', code: '+353' },
  { name: 'Israel', code: '+972' },
  { name: 'Italy', code: '+39' },
  { name: 'Japan', code: '+81' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Mexico', code: '+52' },
  { name: 'Netherlands', code: '+31' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Nigeria', code: '+234' },
  { name: 'Pakistan', code: '+92' },
  { name: 'Philippines', code: '+63' },
  { name: 'Russia', code: '+7' },
  { name: 'Saudi Arabia', code: '+966' },
  { name: 'Singapore', code: '+65' },
  { name: 'South Africa', code: '+27' },
  { name: 'South Korea', code: '+82' },
  { name: 'Spain', code: '+34' },
  { name: 'Sri Lanka', code: '+94' },
  { name: 'Sweden', code: '+46' },
  { name: 'Switzerland', code: '+41' },
  { name: 'Thailand', code: '+66' },
  { name: 'Turkey', code: '+90' },
  { name: 'United Arab Emirates', code: '+971' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'United States', code: '+1' },
  { name: 'Vietnam', code: '+84' }
  // 可以继续添加更多国家
];


const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    topic: '',
    message: '',
  });
  const [countryCode, setCountryCode] = useState('+66'); // 默认泰国区号
  const [captchaToken, setCaptchaToken] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [csrfTokenExpiry, setCsrfTokenExpiry] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 获取 CSRF Token
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-csrf-token`, {
        withCredentials: true,
      });
      const token = response.data.csrfToken;
      const expiryTime = Date.now() + 30 * 60 * 1000; // 令牌有效期 30 分钟
      setCsrfToken(token);
      setCsrfTokenExpiry(expiryTime);
      axios.defaults.headers.common['X-CSRF-Token'] = token; // 全局设置 CSRF 令牌
    } catch (error) {
      console.error('获取 CSRF Token 出错:', error);
    }
  };

  const isCsrfTokenExpired = () => {
    return !csrfTokenExpiry || Date.now() > csrfTokenExpiry;
  };

  // 提交表单
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 验证字段
    const nameRegex = /^[A-Za-zก-๙\s]+$/; // 仅允许泰文、英文和空格
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 验证邮箱格式
    const phoneRegex = /^[0-9]{6,15}$/; // 验证电话号码（不含区号）

if (!nameRegex.test(formData.first_name)) {
  setStatusMessage(
    t('form.errors.invalidFirstName', {
      reason: t('form.errors.invalidNameCharacters'),
    })
  );
  return;
}

if (!nameRegex.test(formData.last_name)) {
  setStatusMessage(
    t('form.errors.invalidLastName', {
      reason: t('form.errors.invalidNameCharacters'),
    })
  );
  return;
}

if (!emailRegex.test(formData.email)) {
  setStatusMessage(
    t('form.errors.invalidEmail', {
      reason: t('form.errors.invalidEmailFormat'),
    })
  );
  return;
}

if (!phoneRegex.test(formData.phone)) {
  setStatusMessage(
    t('form.errors.invalidPhone', {
      reason: t('form.errors.invalidPhoneFormat'),
    })
  );
  return;
}

if (!captchaToken) {
  setStatusMessage(
    t('form.errors.captchaRequired', {
      action: t('form.errors.captchaAction'),
    })
  );
  return;
}

if (isCsrfTokenExpired()) {
  setStatusMessage(
    t('form.errors.csrfExpired', {
      action: t('form.errors.csrfRenewAction'),
    })
  );
  await fetchCsrfToken();
  return;
}

    setIsLoading(true);
    setStatusMessage('');

    try {
      // 发送表单数据到后端
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/contact/submit`,
        {
          ...formData,
          phone: `${countryCode} ${formData.phone}`, // 将国家区号和电话号码合并
          captcha: captchaToken, // 发送验证码 Token
        },
        {
          withCredentials: true,
        }
      );

      setStatusMessage(t('form.success'));
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        topic: '',
        message: '',
      });
      setCaptchaToken(''); // 重置 reCAPTCHA
    } catch (error) {
      setStatusMessage(t('form.errors.submitError'));
      console.error('提交表单错误:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  return (
    <motion.div
      className="contact-form-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <div className="contact-wrapper">
        <div className="contact-company-info">
          <h3>{t('company.title')}</h3>
          <p>{t('company.name')}: ELEPH FILMS LIMITED</p>
          <p>{t('company.address')}: 355 South Teller Street, Suite 1200, Lakewood, CO 80226, United States</p>
          <p>{t('company.email')}: eleph@eleph.tv</p>
          <p>{t('company.hours')}: {t('company.businessHours')}</p>
        </div>
        <div className="contact-form">
          <h2>{t('form.title')}</h2>
          <form onSubmit={handleSubmit} noValidate>
            <input
              type="text"
              name="first_name"
              placeholder={t('form.fields.firstName')}
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="last_name"
              placeholder={t('form.fields.lastName')}
              value={formData.last_name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder={t('form.fields.email')}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="phone-input">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="phone"
                placeholder={t('form.fields.phone')}
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <select
              name="topic"
              value={formData.topic}
              onChange={handleChange}
              required
            >
              <option value="">{t('form.fields.topicPlaceholder')}</option>
              <option value="反馈/评论">{t('form.fields.topicOptions.feedback')}</option>
              <option value="活动/公关关系">{t('form.fields.topicOptions.events')}</option>
              <option value="合作者">{t('form.fields.topicOptions.partners')}</option>
              <option value="申请工作">{t('form.fields.topicOptions.jobs')}</option>
            </select>
            <textarea
              name="message"
              placeholder={t('form.fields.message')}
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <ReCAPTCHA
              sitekey="6LeZa4sqAAAAAMYbWTNpsWWGUsEUoxbLY651GR9v" // Google reCAPTCHA
              onChange={(token) => setCaptchaToken(token)}
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? t('form.loading') : t('form.submit')}
            </button>
          </form>
          {statusMessage && <p className="status-message">{statusMessage}</p>}
        </div>
      </div>
    </motion.div>
  );
};

export default ContactForm;
