import React from "react";
import "./PartnerLogoSlider.css";

// Update the imports with the correct path based on your project structure
import googleLogo from "../assets/1.png";
import tMobileLogo from "../assets/2.png";
import aaveLogo from "../assets/3.png";
import nbaLogo from "../assets/4.png";
import synthetixLogo from "../assets/5.png";
import lemonadeLogo from "../assets/6.png";
import swisscomLogo from "../assets/7.png";
import compoundLogo from "../assets/8.png";
import nexusMutualLogo from "../assets/9.png";

const PartnerLogoSlider = () => {
  const partners = [
    { name: "1", logo: googleLogo },
    { name: "2", logo: tMobileLogo },
    { name: "3", logo: aaveLogo },
    { name: "4", logo: nbaLogo },
    { name: "5", logo: synthetixLogo },
    { name: "6", logo: lemonadeLogo },
    { name: "7", logo: swisscomLogo },
    { name: "8", logo: compoundLogo },
    { name: "9", logo: nexusMutualLogo },
  ];

  // Double the partner array to ensure seamless animation
  const doubledPartners = [...partners, ...partners];

  return (
    <div className="partner-logo-slider">
      <div className="partner-logo-slider-inner">
        {doubledPartners.map((partner, index) => (
          <div className="partner-logo" key={index}>
            <img src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerLogoSlider;
