import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // 检测浏览器语言并自动切换
  useEffect(() => {
    const browserLang = navigator.language || navigator.userLanguage; // 获取浏览器语言
    const languageCode = browserLang.split('-')[0].toLowerCase(); // 提取语言代码 (如 "en" 或 "th")
    console.log('Detected browser language:', browserLang);

    if (languageCode !== i18n.language) {
      // 如果当前语言与浏览器语言不同，切换语言
      i18n.changeLanguage(languageCode)
        .then(() => console.log('Language automatically switched to:', i18n.language))
        .catch((err) => console.error('Failed to switch language automatically:', err));
    }
  }, [i18n]); // 仅在组件挂载或 `i18n` 实例变化时运行

  // 切换语言的处理函数
  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value.toLowerCase(); // 确保小写语言代码
    console.log('Manually switching to language:', selectedLang);

    i18n.changeLanguage(selectedLang)
      .then(() => console.log('Language successfully switched to:', i18n.language))
      .catch((err) => console.error('Failed to switch language:', err));
  };

  return (
    <div className="language-switcher-container">
      <select
        id="language-select"
        value={i18n.language} // 当前语言
        onChange={handleLanguageChange} // 切换语言事件
        className="language-switcher"
      >
        <option value="en">English</option>
        <option value="th">ประเทศไทย</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
