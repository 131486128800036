import React, { useState, useEffect } from "react";
import "./TopBar.css";
import logoDesktop from "../assets/desktop-logo.png";
import logoMobile from "../assets/mobile-logo.png";
import { useTranslation } from "react-i18next"; // 多语言 Hook
import LanguageSwitcher from "../components/LanguageSwitcher"; // 语言切换组件
import { Link, useNavigate } from "react-router-dom"; // 支持页面跳转

function TopBar() {
  const { t } = useTranslation(); // 获取翻译函数
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDayTheme, setIsDayTheme] = useState(false);
  const navigate = useNavigate(); // 初始化导航函数

  // 切换菜单的显示状态
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  // 切换主题
  const toggleTheme = () => {
    setIsDayTheme((prevState) => !prevState);
  };

  // 设置初始主题（基于泰国时间）
  useEffect(() => {
    const setInitialTheme = () => {
      const now = new Date();
      const localTimeOffset = now.getTimezoneOffset();
      const thailandTimeOffset = 7 * 60;
      const thailandTime = new Date(now.getTime() + (thailandTimeOffset - localTimeOffset) * 60000);
      const hours = thailandTime.getHours();
      setIsDayTheme(hours >= 6 && hours < 18); // 白天为 6:00 至 18:00
    };

    setInitialTheme();
  }, []);

  // 动态设置 body 的 className
  useEffect(() => {
    document.body.className = isDayTheme ? "day-theme" : "night-theme";
  }, [isDayTheme]);

  // 点击页面其他区域时关闭菜单
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".navigation") && !e.target.closest(".hamburger-menu")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  // Logo 点击跳转到首页
  const handleLogoClick = () => {
    navigate("/"); // 跳转到首页
  };

  return (
    <div className="top-bar">
      {/* Desktop Logo */}
      <img
        src={logoDesktop}
        alt="Desktop Logo"
        className="logo logo-desktop"
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }} // 添加指针样式
      />
      {/* Mobile Logo */}
      <img
        src={logoMobile}
        alt="Mobile Logo"
        className="logo logo-mobile"
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }} // 添加指针样式
      />

      <div className="center-content">
        {/* 导航菜单 */}
        <nav className={`navigation ${isMenuOpen ? "open" : ""}`}>
          <Link to="/#home">{t("navigation.home")}</Link>
          <Link to="/#about">{t("navigation.about")}</Link>
          <Link to="/#services">{t("navigation.services")}</Link>
          <Link to="/contact">{t("navigation.contact")}</Link>
        </nav>

        {/* 主题切换按钮 */}
        <button className="theme-toggle-button" onClick={toggleTheme}>
          {isDayTheme ? t("theme.day") : t("theme.night")}
        </button>

        {/* 语言切换组件 */}
        <div className="language-switcher-container">
          <LanguageSwitcher />
        </div>
      </div>

      {/* 移动端汉堡菜单 */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default TopBar;
