import React, { useEffect } from 'react';
import './BackgroundAnimation.css';

function BackgroundAnimation() {
  useEffect(() => {
    const canvas = document.getElementById('star-canvas');
    const ctx = canvas.getContext('2d');

    // 自适应屏幕尺寸
    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // 创建星星对象
    function Star() {
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.radius = Math.random() * 1.8 + 0.8;
      this.alpha = Math.random() * 0.5 + 0.5;
      this.twinkle = Math.random() * 0.005 + 0.002; // 增大闪烁频率
    }

    const starCount = window.innerWidth < 768 ? 100 : 200; // 根据屏幕尺寸设置星星数量
    const stars = Array.from({ length: starCount }, () => new Star());

    // 动画函数
    function animateStars() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      stars.forEach(star => {
        star.alpha += star.twinkle * (Math.random() > 0.5 ? 1 : -1);
        if (star.alpha < 0.2 || star.alpha > 1) star.twinkle = -star.twinkle;

        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;
        ctx.fill();
      });
      requestAnimationFrame(animateStars);
    }

    animateStars();

    // 创建流星
    const createMeteor = () => {
      const meteor = document.createElement('div');
      meteor.className = 'meteor';

      // 随机设置流星出现的位置
      meteor.style.top = `${Math.random() * window.innerHeight}px`;
      meteor.style.left = `${Math.random() * window.innerWidth}px`;

      document.body.appendChild(meteor);

      // 流星消失后移除
      meteor.addEventListener('animationend', () => {
        meteor.remove();
      });

      // 随机时间生成新的流星
      setTimeout(createMeteor, Math.random() * 10000 + 5000);
    };

    createMeteor();

    // 清理事件监听器
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  return (
    <div className="background-animation">
      <canvas id="star-canvas"></canvas>
    </div>
  );
}

export default BackgroundAnimation;
