import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AddToHomePrompt from "./components/AddToHomePrompt";
import ContactForm from "./components/ContactForm"; // 引入联系表单组件
import TopBar from "./components/TopBar"; // 引入导航栏组件

function App() {
    return (
        <Router>
            <div className="App">
                {/* 顶部导航栏 */}
                <TopBar />
                {/* 引导提示组件 */}
                <AddToHomePrompt />
                {/* 路由配置 */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/contact" element={<ContactForm />} /> {/* 添加联系我们页面的路由 */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
