import React, { createContext, useState, useEffect } from "react";
import { translateTexts } from "../utils/translate";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("TH"); // 默认语言
  const [translations, setTranslations] = useState({}); // 缓存的翻译内容

  const translatePageTexts = async (texts) => {
    const translated = await translateTexts(texts, language);
    setTranslations((prev) => ({
      ...prev,
      ...texts.reduce((acc, text, index) => {
        acc[text] = translated[index];
        return acc;
      }, {}),
    }));
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations, translatePageTexts }}>
      {children}
    </LanguageContext.Provider>
  );
};
