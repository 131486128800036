// C:\luodi\netflix-landing\src\utils\translate.js

import axios from 'axios';

export async function translateText(text, targetLang) {
    try {
        const response = await axios.post('http://localhost:5000/api/translate', {
            texts: [text],
            targetLang: targetLang,
        });

        // 打印响应以查看其结构
        console.log('Translation response: ', response.data);

        // 检查响应中的 translatedTexts 数组是否存在且不为空
        if (response.data.translatedTexts && response.data.translatedTexts.length > 0) {
            return response.data.translatedTexts[0];
        } else {
            console.error('Single text translation failed: response structure does not contain expected translatedTexts array or array is empty.');
            return text; // 如果没有翻译结果，则返回原始文本作为回退
        }
    } catch (error) {
        console.error('Translation request failed:', error);
        return text; // 如果请求失败，返回原始文本作为回退
    }
}

// 添加 translateTexts 函数来处理多个文本的翻译请求
export async function translateTexts(texts, targetLang) {
    try {
        const response = await axios.post('http://localhost:5000/api/translate', {
            texts: texts,
            targetLang: targetLang,
        });

        // 打印响应以查看其结构
        console.log('Translation response: ', response.data);

        // 检查响应中的 translatedTexts 数组是否存在且不为空
        if (response.data.translatedTexts && response.data.translatedTexts.length > 0) {
            return response.data.translatedTexts;
        } else {
            console.error('Multiple texts translation failed: response structure does not contain expected translatedTexts array or array is empty.');
            return texts; // 如果没有翻译结果，则返回原始文本作为回退
        }
    } catch (error) {
        console.error('Translation request failed:', error);
        return texts; // 如果请求失败，返回原始文本作为回退
    }
}
