import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // 引入翻译 Hook
import HeroBanner from "../components/HeroBanner";
import CompanyInfoSection from "../components/CompanyInfoSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import BackgroundAnimation from "../components/BackgroundAnimation";
import PartnerLogoSlider from "../components/PartnerLogoSlider";
import VideoIntroduction from "../components/VideoIntroduction";
import MoviesSection from "../components/MoviesSection";
import SocialShare from "../components/SocialShare";
import API_BASE_URL from "../config";

function HomePage() {
  const { t } = useTranslation(); // 获取 t 函数
  const [showShare, setShowShare] = useState(false);
  const currentUrl = window.location.href;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight - 50) {
        setShowShare(true);
      } else {
        setShowShare(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="home-page">
      <BackgroundAnimation />
      <HeroBanner />
      <CompanyInfoSection />
      <MoviesSection
        title={t("movies.popular")}
        category="trending"
      />
      <MoviesSection
        title={t("movies.upcoming")}
        category="netflix-exclusive"
      />
      <PartnerLogoSlider />
      <VideoIntroduction />
      <FAQSection apiUrl={`${API_BASE_URL}/faqs`} />
      {showShare && (
        <SocialShare
          url={currentUrl}
          title={t("social.shareMessage")}
        />
      )}
      <Footer apiUrl={`${API_BASE_URL}/footer`} />
    </div>
  );
}

export default HomePage;
