import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // 引入 i18next
import "./HeroBanner.css";
import API_BASE_URL from "../config";

function HeroBanner() {
  const { t, i18n } = useTranslation(); // 获取翻译函数和当前语言
  const [bannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    // 从后端获取大图信息
    fetch(`${API_BASE_URL}/banners?lang=${i18n.language}`) // 动态传递语言参数
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setBannerImages(data.map((item) => item.imageUrl)); // 假设API返回的每个项目包含imageUrl字段
        }
      })
      .catch((error) => console.error("Error fetching banner images: ", error));
  }, [i18n.language]); // 监听语言变化，动态加载图片

  return (
    <div className="tv-frame">
      <div className="overlay">
        <div className="bottom-center-content">
          <h1 className="title">{t("heroBanner.title")}</h1>
          <p className="subtitle">{t("heroBanner.subtitle")}</p>
        </div>
      </div>
      <div className="hero-banner">
        <div className="slider">
          {bannerImages.concat(bannerImages).map((imageUrl, index) => (
            <img key={index} src={imageUrl} alt={t("heroBanner.imageAlt")} className="slider-image" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
