import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // 引入 i18n hook
import './AddToHomePrompt.css';

const AddToHomePrompt = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation(); // 使用 i18n 的翻译功能

    useEffect(() => {
        const isStandalone = window.navigator.standalone;
        const userAgent = window.navigator.userAgent;
        const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
        const isSafari = /Safari/i.test(userAgent) && !/CriOS|FxiOS/i.test(userAgent);

        // 如果是 iOS Safari 且没有安装为桌面应用
        if (isIOS && isSafari && !isStandalone && !localStorage.getItem('addToHomePromptShown')) {
            setIsVisible(true);
            localStorage.setItem('addToHomePromptShown', 'true'); // 避免重复提示
        }
    }, []);

    // 关闭提示
    const closePrompt = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="add-to-home-prompt">
            <div className="prompt-content">
                <p>
                    {t('addToHome.instructions')} {/* 动态翻译文本 */}
                    <span className="share-icon">⤴️</span> 
                    <strong>{t('addToHome.addToScreen')}</strong>
                </p>
                <div className="arrow-animation">⬇️</div>
                <button onClick={closePrompt} className="close-btn">
                    {t('addToHome.close')} {/* 动态翻译关闭按钮 */}
                </button>
            </div>
        </div>
    );
};

export default AddToHomePrompt;
