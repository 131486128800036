import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // 引入多语言功能
import "./FAQSection.css";

function FAQSection({ apiUrl }) {
  const { t, i18n } = useTranslation(); // 获取翻译函数和当前语言
  const [faqs, setFaqs] = useState([]);
  const [randomFaqs, setRandomFaqs] = useState([]);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // 搜索关键词
  const [error, setError] = useState(null);

  useEffect(() => {
    // 从后端获取 FAQ 数据
    const fetchFAQs = async () => {
      try {
        const response = await fetch(`${apiUrl}?lang=${i18n.language}`); // 根据当前语言请求数据
        if (!response.ok) {
          throw new Error(`请求失败，状态码：${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setFaqs(data); // 保存所有 FAQ 数据
          setRandomFaqs(getRandomFaqs(data, 5)); // 初始化随机 5 个问题
        } else {
          setFaqs([]);
          setRandomFaqs([]);
        }
      } catch (err) {
        console.error("获取 FAQ 数据时出错: ", err);
        setError(t("faq.error")); // 使用翻译的错误提示
      }
    };

    fetchFAQs();
  }, [apiUrl, i18n.language]); // 监听语言变化

  // 获取随机的 n 个 FAQ
  const getRandomFaqs = (faqList, n) => {
    const shuffled = [...faqList].sort(() => 0.5 - Math.random()); // 随机排序
    return shuffled.slice(0, n); // 获取前 n 个
  };

  const toggleFAQ = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  // 搜索功能逻辑
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    if (!searchValue) {
      setRandomFaqs(getRandomFaqs(faqs, 5)); // 搜索框为空时恢复随机问题
    } else {
      const filtered = faqs.filter(
        (faq) =>
          faq.question.toLowerCase().includes(searchValue) ||
          faq.answer.toLowerCase().includes(searchValue)
      );
      setRandomFaqs(filtered); // 显示符合条件的搜索结果
    }
  };

  return (
    <div className="faq-section">
      <h2>{t("faq.title")}</h2> {/* 动态翻译标题 */}
      {error && <p className="error-message">{error}</p>}

      {/* 搜索框 */}
      <input
        type="text"
        placeholder={t("faq.searchPlaceholder")} // 动态翻译占位符
        value={searchTerm}
        onChange={handleSearch}
        className="faq-search"
      />

      {/* FAQ 列表 */}
      <div className="faq-list">
        {randomFaqs.length > 0 ? (
          randomFaqs.map((faq, index) => (
            <div key={faq._id || faq.id} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question} {/* 问题 */}
                <span className="faq-toggle">
                  {expandedQuestion === index ? "-" : "+"}
                </span>
              </div>
              {expandedQuestion === index && (
                <div className="faq-answer">{faq.answer}</div> // 答案
              )}
            </div>
          ))
        ) : (
          !error && <p>{t("faq.noData")}</p> // 动态翻译无数据提示
        )}
      </div>
    </div>
  );
}

export default FAQSection;
