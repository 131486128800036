import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLine,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./SocialShare.css";

const SocialShare = ({ url, title }) => {
  const [expanded, setExpanded] = useState(false);

  // 社交媒体分享链接和图标
  const shareLinks = [
    {
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`,
      icon: faFacebook,
      color: "#1877F2", // Facebook 蓝
    },
    {
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(title)}`,
      icon: faTwitter,
      color: "#1DA1F2", // Twitter 蓝
    },
    {
      name: "Line",
      url: `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
        url
      )}`,
      icon: faLine,
      color: "#00C300", // Line 绿
    },
    {
      name: "WhatsApp",
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        title + " " + url
      )}`,
      icon: faWhatsapp,
      color: "#25D366", // WhatsApp 绿
    },
  ];

  return (
    <div className={`social-share ${expanded ? "expanded" : ""}`}>
      {/* 主分享图标按钮 */}
      <button
        className="main-share-button"
        onClick={() => setExpanded(!expanded)}
      >
        <FontAwesomeIcon icon={faShareAlt} />
      </button>
      {/* 社交分享选项 */}
      {expanded && (
        <div className="share-options">
          {shareLinks.map((link) => (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="share-button"
              style={{ color: link.color }}
            >
              <FontAwesomeIcon icon={link.icon} />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default SocialShare;
