import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Icon } from "@iconify/react";
import tiktokIcon from "@iconify/icons-simple-icons/tiktok";
import lineIcon from "@iconify/icons-simple-icons/line";
import { useTranslation } from "react-i18next"; // 引入 i18next

// 动态获取图标函数（支持更多社交图标）
function getIconByName(name) {
  const icons = {
    facebook: "mdi:facebook",
    twitter: "mdi:twitter",
    instagram: "mdi:instagram",
    youtube: "mdi:youtube",
    linkedin: "mdi:linkedin",
    pinterest: "mdi:pinterest",
    reddit: "mdi:reddit",
    snapchat: "mdi:snapchat",
    tiktok: tiktokIcon,
    whatsapp: "mdi:whatsapp",
    github: "mdi:github",
    tumblr: "mdi:tumblr",
    line: lineIcon,
    internet: "mdi:web",
  };
  return icons[name?.toLowerCase()] || "mdi:help-circle-outline"; // 未知名称返回问号图标
}

function Footer({ apiUrl }) {
  const { t, i18n } = useTranslation(); // 获取翻译函数和当前语言
  const [footerData, setFooterData] = useState({ description: "", socialLinks: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 从后端获取 Footer 数据
    const fetchFooterData = async () => {
      setLoading(true);
      setError(null);
      try {
        const lang = i18n.language; // 获取当前语言
        const url = `${apiUrl}`; // 动态传递语言参数
        const res = await fetch(url);
        if (!res.ok) throw new Error(`HTTP 错误状态码: ${res.status}`);
        const data = await res.json();
        setFooterData({
          description: data.description || t("footer.defaultDescription"),
          socialLinks: Array.isArray(data.socialLinks) ? data.socialLinks : [],
        });
      } catch (err) {
        console.error("获取 Footer 数据失败:", err);
        setError(t("footer.error")); // 动态加载错误消息
      } finally {
        setLoading(false);
      }
    };

    fetchFooterData();
  }, [apiUrl, i18n.language]); // 监听语言变化

  return (
    <footer className="footer">
      <div className="footer-content">
        {loading ? (
          <p>{t("loading")}</p> // 动态加载提示
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <>
            <p>{footerData.description}</p>
            <div className="social-links">
              {footerData.socialLinks.length > 0 ? (
                footerData.socialLinks.map((link, index) => (
                  link && link.name && link.url ? ( // 确保 link 对象存在并且含有 name 和 url
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      {getIconByName(link.name) ? (
                        <Icon
                          icon={getIconByName(link.name)}
                          title={t(`social.${link.name.toLowerCase()}`)} // 动态加载社交链接名称
                          style={{ fontSize: "1.5rem", marginRight: "10px" }}
                        />
                      ) : (
                        <img
                          src={link.icon || "default-icon.png"} // 如果后端提供了自定义图标链接
                          alt={t(`social.${link.name.toLowerCase()}`)}
                          className="custom-icon"
                        />
                      )}
                    </a>
                  ) : null
                ))
              ) : (
                <p>{t("footer.noSocialLinks")}</p> // 动态加载无社交链接提示
              )}
            </div>
          </>
        )}
      </div>
    </footer>
  );
}

export default Footer;
