import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // 引入 i18next
import "./CompanyInfoSection.css";
import licenseImage from "../assets/about-eleph.png"; // 引入营业执照图片

function CompanyInfoSection() {
  const { t } = useTranslation(); // 获取翻译函数
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 打开模态窗口
  const openModal = () => setIsModalOpen(true);

  // 关闭模态窗口
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="company-info-section">
      {/* 进入官网按钮 */}
      <button
        className="info-button"
        onClick={() => window.location.href = "https://eleph.vip"}
      >
        🌐 {t("company.visitWebsite")}
      </button>

      {/* 关于公司按钮 */}
      <button className="info-button" onClick={openModal}>
        🏆 {t("company.aboutCompany")}
      </button>

      {/* 模态窗口 */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>✕</button>
            <h2>{t("company.aboutElephTitle")}</h2>
            <p>{t("company.aboutElephDescription")}</p>
            <img src={licenseImage} alt={t("company.licenseAlt")} className="license-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyInfoSection;
