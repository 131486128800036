import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./MoviesSection.css";
import API_BASE_URL from "../config";
import { useTranslation } from "react-i18next"; // 引入 i18next

// 自定义 Hook 获取电影数据
function useFetchMovies(category, lang) {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (category) {
      fetch(`${API_BASE_URL}/movies/${category}?lang=${lang}`) // 加入语言参数
        .then((res) => {
          if (!res.ok) throw new Error("网络响应失败");
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) setMovies(data);
          else throw new Error("返回的数据格式不正确");
        })
        .catch((error) => setError(error.message))
        .finally(() => setIsLoading(false));
    } else {
      setError("无效的分类参数");
      setIsLoading(false);
    }
  }, [category, lang]); // 当语言变化时重新获取数据

  return { movies, isLoading, error };
}

// 滑动组件
function MovieSlider({ movies, openModal, isModalOpen }) {
  const containerRef = useRef(null);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const movieCard = containerRef.current.querySelector(".movie-card");
      if (movieCard) setCardWidth(movieCard.offsetWidth + 20);
    }
  }, [movies]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleScroll = () => {
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        if (leftButtonRef.current) {
          leftButtonRef.current.style.display = container.scrollLeft > 0 ? "block" : "none";
        }

        if (rightButtonRef.current) {
          rightButtonRef.current.style.display = container.scrollLeft >= maxScrollLeft ? "none" : "block";
        }

        setScrollAmount(container.scrollLeft);
      };

      container.addEventListener("scroll", handleScroll);
      handleScroll(); // 初始化时确保按钮状态正确

      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [movies, isModalOpen]);

  const handleScroll = (direction) => {
    if (isScrolling) return;
    setIsScrolling(true);

    if (containerRef.current) {
      const container = containerRef.current;
      const maxScroll = container.scrollWidth - container.offsetWidth;
      const newScrollAmount = Math.min(
        Math.max(scrollAmount + direction * cardWidth * 2, 0),
        maxScroll
      );
      setScrollAmount(newScrollAmount);
      container.scrollTo({ left: newScrollAmount, behavior: "smooth" });
    }

    setTimeout(() => setIsScrolling(false), 500);
  };

  return (
    <div className="movies-grid">
      {!isModalOpen && (
        <>
          <button
            className="nav-button left"
            ref={leftButtonRef}
            onClick={() => handleScroll(-1)}
            disabled={isScrolling || scrollAmount === 0}
          >
            <FaArrowLeft />
          </button>
          <button
            className="nav-button right"
            ref={rightButtonRef}
            onClick={() => handleScroll(1)}
            disabled={
              isScrolling ||
              (containerRef.current &&
                scrollAmount >=
                  containerRef.current.scrollWidth -
                    containerRef.current.offsetWidth)
            }
          >
            <FaArrowRight />
          </button>
        </>
      )}
      <div
        className="movies-container"
        ref={containerRef}
        style={{ display: "flex", overflow: "hidden" }}
      >
        {movies.map((movie, index) => (
          <div
            key={index}
            className="movie-card"
            onClick={() => openModal(movie)}
          >
            <img src={movie.thumbnailUrl} alt={movie.title} />
            <h3>{movie.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

// 主组件
function MoviesSection({ title, category }) {
  const { t, i18n } = useTranslation(); // 获取翻译函数和当前语言
  const { movies, isLoading, error } = useFetchMovies(category, i18n.language); // 动态传递当前语言
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (movie) => {
    setSelectedMovie(movie);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMovie(null);
  };

  return (
    <div className="movies-section">
      <h2>{t(title)}</h2> {/* 动态翻译标题 */}
      {isLoading ? (
        <p>{t("loading")}</p> // 动态加载提示
      ) : error ? (
        <p className="error-message">{t("error.loadingFailed")}</p> // 动态错误提示
      ) : (
        <div className="slider-wrapper">
          {movies.length > 0 ? (
            <MovieSlider
              movies={movies}
              openModal={openModal}
              isModalOpen={isModalOpen}
            />
          ) : (
            <p>{t("movies.noData")}</p> // 动态无数据提示
          )}
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={t("movies.details")}
        ariaHideApp={false}
        className="movie-modal"
        overlayClassName="movie-modal-overlay"
      >
        {selectedMovie && (
          <div className="modal-content">
            <img src={selectedMovie.detailImageUrl} alt={selectedMovie.title} />
            <div className="movie-details">
              <h2>{selectedMovie.title}</h2>
              <div className="movie-tags">
                {selectedMovie.year && (
                  <span className="tag">{selectedMovie.year}</span>
                )}
                {selectedMovie.ageRestriction && (
                  <span className="tag">{selectedMovie.ageRestriction}</span>
                )}
                {selectedMovie.genre &&
                  selectedMovie.genre.split(",").map((genre, index) => (
                    <span key={index} className="tag">
                      {genre.trim()}
                    </span>
                  ))}
              </div>
              <p>{selectedMovie.description}</p>
            </div>
            <button onClick={closeModal} className="close-modal-button">
              {t("movies.close")}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default MoviesSection;
